import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/ServiceHomeRemoteVisits'
import RelatedServices from '../components/ServiceDetails/RelatedServices'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Home and Remote Visits" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Home and Remote Visits" 
            />
            <ServiceDetailsContent />
            <Footer />
        </Layout>
    );
}

export default Details