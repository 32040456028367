import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/HomeVisitKitchen.png'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3>Home Visits</h3>
                            <p>Occupational Therapy ‘home visit’ assessments are fundamental to person centered care and hospital discharges. They provide the Occupational Therapist with an opportunity to visit patients’ homes and determine the care, equipment and adaptations they need to return home safely. For example they may measure the heights of the toilet, note the types of stairs and steps present consider how this physical environment may affect the patient’s ability to safely carry out everyday tasks. This assessment is then used to plan hospital discharges with patients themselves and other health and social care professionals.</p>
                           
                            <p>People living with disabilities face daily challenges getting through their day. Our mobile service assists clients remain independant in their own home.</p>

                            <h3>TeleHealth</h3>
                            <p>The COVID-19 pandemic necessitated a rapid shift to delivering remote care and rehabilitation. In Australia, Telehealth became a common term that referred to health or medical services being provided via various technology platforms or videoconferencing. </p>                           

                            <p>Advantages of TeleHealth in addition to minimising the chance of infection with COVID-19 are:</p>
                        
                            <p>- No Travel Costs</p>
                            <p>- Therapy from the convenience of your own home</p>
                            <p>- Flexibility for those with busy work/life schedules</p>
                            <p>- If you live remote/rural you can still access online therapy</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent